@import (reference) '~@falla/less/index';

.home {
  width: 100%;
  min-width: 1400px;
  min-height: 937px;
  height: 100%;
  background-image: url('./assets/bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-color: @yichat-primary-color;
  overflow: hidden;
}

.wrapper {
  width: 1290px;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 41px;
  padding-bottom: 54px;
  direction: ltr;

  .logo {
    display: flex;
    align-items: center;
    font-size: 46px;
    font-weight: bold;
    color: #121215;

    img {
      width: 90px;
      height: 90px;
    }
  }

  .right {
    display: flex;
    align-items: center;
  }

  .rechargeBtn {
    min-width: 100px;
    height: 44px;
    border-radius: 22px;
    font-size: 18px;
  }

  .lang {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    font-weight: 500;
    color: #121215;
    font-size: 20px;

    &:hover {
    }

    .label {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 118px;
      height: 45px;
      border-radius: 14px;
      border: 1px solid #121215;

      span {
        //margin-right: 17px;
      }
      img {
        width: 24px;
        height: 24px;
      }
    }

    .langList {
      width: 100%;
      position: absolute;
      top: 50px;
      padding-bottom: 16px;
      //text-align: center;
      padding-left: 12px;
      direction: ltr;
      text-align: left;
    }
  }
}

.content {
  padding: 0 40px;
  //display: flex;
  //justify-content: space-between;
  position: relative;
  display: block;

  .slogan {
    text-align: center;
  }

  .appPreview {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 45px;

    .appPreviewCover {
      width: 249px;
      height: 535px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .title {
    font-weight: bold;
    font-size: 32px;
    color: #121215;
  }

  .subTitle {
    font-size: 28px;
    color: #121215;
  }

  .download {
    display: none;
    display: flex;
    margin-top: 20px;
    justify-content: center;
    //display: none;

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 230px;
      height: 80px;
      font-size: 26px;
      color: #ffffff;
      background: #02080c;
      border-radius: 16px;
      border: 2px solid #000000;
      cursor: pointer;
      transition: @all-transition-base;

      &:last-child {
        //margin-left: 24px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.footer {
  width: 100%;
  text-align: center;
  padding-top: 40px;
  //padding-bottom: 78px;
  margin: 0 auto;
  font-size: 18px;
  color: #121215;

  .linkBtn {
    font-size: 24px;
    color: #666666;
    cursor: pointer;
    transition: @all-transition-base;

    &:hover {
      opacity: 0.8;
      text-decoration: underline;
    }
  }

  .divide {
    display: inline-block;
    width: 2px;
    height: 15px;
    margin: 0 24px;
    background: #999999;
  }

  .copyright {
  }
}

html[mobile='true'] {
  .home {
    min-width: 100%;
    height: 100%;
    min-height: 530px;
  }
  .wrapper {
    width: 100%;
  }

  .header {
    padding: 20px;
    direction: ltr;

    .logo {
      font-size: 28px;

      img {
        width: 45px;
        height: 45px;
      }
    }

    .lang {
      font-size: 18px;

      &:hover {
      }

      .label {
        width: (118px / 1.2);
        height: (45px / 1.2);
        border-radius: 12px;

        img {
          width: 20px;
          height: 20px;
        }
      }

      .langList {
        top: 40px;
        padding-bottom: 12px;
        padding-left: 10px;
      }
    }
  }

  .content {
    padding: 0 32px;

    .appPreview {
      margin-top: 36px;

      .appPreviewCover {
        width: (140px/1.2);
        height: (300px/ 1.2);
      }
    }

    .title {
      font-size: 26px;
    }

    .subTitle {
      font-size: 22px;
    }
  }

  .footer {
    width: 100%;
    text-align: center;
    padding-top: 40px;
    //padding-bottom: 78px;
    margin: 0 auto;
    font-size: 18px;
    color: #121215;

    .linkBtn {
      font-size: 24px;
      color: #666666;
      cursor: pointer;
      transition: @all-transition-base;

      &:hover {
        opacity: 0.8;
        text-decoration: underline;
      }
    }

    .divide {
      display: inline-block;
      width: 2px;
      height: 15px;
      margin: 0 24px;
      background: #999999;
    }

    .copyright {
    }
  }
}
